import * as React from 'react';
import './Modal.css'
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import BasicInfoForm from '../../components/Forms/BasicInfoForm';
import PrescriptionForm from '../../components/Forms/PrescriptionForm';

import AlertDialog from '../../components/Dialog/AlertDialog';
// import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = (props) => {
	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (props.open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [props.open]);
	const steps = [
		'Basic Information',
		'Prescription Information',
	];

	function getStepContent(step) {
		
		switch (step) {
			case 0:
				return <BasicInfoForm onInputchange={(e,a) => props.onInputchange(e,a)} state={props.state} />;
			case 1:
				return <PrescriptionForm onInputchange={props.onInputchange} state={props.state} />;
			default:
				return <PrescriptionForm onInputchange={props.onInputchange} state={props.state} />;
		}
	}

	// const [value, setValue] = React.useState(new Date(''));
	// const handleChange = (newValue) => {
	// 	setValue(newValue);
	// };
	const Input = styled('input')({
		display: 'none',
	});
	const [state] = React.useState({
		terms: true
	});
	const { terms } = state;
	
	return (
    <>
		{ ( props.buttanEnable === true ) ?
    	<Button onClick={props.handleClickOpen('paper')} color="primary" variant="contained" disableElevation sx={{mt: 4, borderRadius: '25px'}} /*data-aos="fade-up"*/>Enroll now</Button> : null }
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			scroll={props.scroll}
			fullScreen
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			TransitionComponent={Transition}
		>
			<DialogTitle id="scroll-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
				Member Order Form
				{props.handleClose ? (
				<IconButton
					aria-label="close"
					onClick={props.handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.primary.main,
					}}
				>
					<CloseIcon />
				</IconButton>
				) : null}
			</DialogTitle>
			<DialogContent dividers={props.scroll === 'paper'}>
				<DialogContentText
					id="scroll-dialog-description"
					ref={descriptionElementRef}
					tabIndex={-1}
					position="relative"
					component="div"
				>
					<Stepper nonLinear activeStep={props.activeStep} alternativeLabel sx={{mb: 4}}>
						{steps.map((label, index) => (
							<Step key={label} completed={props.completed[index]}>
								<StepLabel color="primary">{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					
					{/* Dynamic form content */}
					<React.Fragment>
						{getStepContent(props.activeStep)}
					</React.Fragment>
					
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
					<Button
						color="primary" variant="outlined" disableElevation
						disabled={props.activeStep === 0}
						onClick={props.handleBack}
						className={"BackButton"}
						sx={{ mr: 1, mb: 2, borderRadius: '25px' }}
					>
						Back
					</Button>
					{/* <Button color="primary" variant="contained" disableElevation sx={{mb: 2, borderRadius: '25px'}} onClick={props.handleNext}>
						{props.activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
					</Button> */}

					<LoadingButton 
						color="primary" 
						onClick={props.handleNext} 
						loading={ props.loadingMemberForm } 
						sx={{mb: 2, borderRadius: '25px'}} 
						variant="contained" 
					>
						{props.activeStep === 0 ? 'Continue' : 'Submit'}
					</LoadingButton>
					
				</Box>
			</DialogActions>
		</Dialog>
		
		<AlertDialog 
			openAlertDialog={props.openAlertDialog} 
			handleAlertDialogClose={props.handleAlertDialogClose} message = "Your request has been sent successfully. An RxFree4Me representative will be in contact with you shortly to explain the next steps. Thank you for enrolling." />
    </>
  );
}

export default Modal;