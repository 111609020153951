import * as React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Navbar from "./components/Navbar/Navbar";

import Home from "./pages/Home/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import MemberRights from "./pages/MemberRights";
import Footer from "./components/Footer/Footer";

import Axios from "axios";

const theme = createTheme({
	typography: {
		fontFamily: "Sen, sans-serif",
		fontSize: 16,
		h1: {
			fontSize: 42,
		},
		h2: {
			fontSize: 40,
			fontWeight: 700,
		},
		h3: {
			fontSize: 20,
			fontWeight: 700,
		},
		h4: {
			fontSize: 18,
			fontWeight: 700,
		},
		subtitle1: {
			fontFamily: "Poppins, sans-serif",
			fontSize: 124,
			lineHeight: 1.7,
			letterSpacing: 8,
			color: "#ffffff",
			opacity: 0.11,
		},
		subtitle2: {
			fontFamily: "Satisfy, cursive",
			fontSize: 32,
			color: "#BF1E29",
		},
		body1: {
			fontSize: 20,
			lineHeight: 1.7,
			color: "#444444",
		},
		body2: {
			fontSize: 16,
			lineHeight: 1.7,
			color: "#444444",
		},
	},
	palette: {
		primary: {
			main: "#001FC5",
			light: "#F6FAFF",
		},
		secondary: {
			main: "#BF1E29",
		},
	},
});

class App extends React.Component {
	state = {
		isErrorInForm: false,
		openModal: false,
		openRefillModal: false,
		openAlertDialog: false,
		scroll: "paper",
		prevActiveStep: 0,
		activeStep: 0,
		completed: [],
		newCompleted: [],
		first_name: "",
		first_name_error: false,
		broker_tpa_error: false,
		last_name: "",
		email: "",
		email_error: false,
		email_validation_error: false,
		mobile: "",
		member_id: "",
		person_code: "",
		street: "",
		apartment_number: "",
		city: "",
		orderState: "",
		zipcode: "",
		date_of_birth_error: false,
		date_of_birth: "",
		date_of_birth_format: new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}).format(new Date()),
		employer_group: "",
		employer_group_error: false,
		employer_group_id: "",
		employer_group_text: "",
		drug_name: "",
		drug_name_error: false,
		upload_prescription_error: false,
		upload_prescription: {},
		upload_prescription_name: "",
		physician_name: "",
		physician_number: "",
		physician_fax_number: "",
		diagnosis: "",
		drug_allergies: "",
		drug_allergies_text: "",
		other_medications: "",
		other_medications_text: "",
		terms: false,
		terms_error: false,
		loadingMemberForm: false,
		broker_tpa: "",
		broker_tpa_id: null,
		broker_tpa_text: null,
		request_submitted_by: "",

		refill_email_error: false,
		refill_email_validation_error: false,
		refill_email: "",
		refill_first_name_error: false,
		refill_first_name: "",
		refill_last_name: "",
		refill_date_of_birth: "",
		refill_date_of_birth_format: new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}).format(new Date()),
		refill_date_of_birth_error: false,
		refill_drug_name: "",
		refill_drug_name_error: false,
		refill_terms: "off",
		refill_terms_error: false,
		refill_employer_group: "",
		refill_employer_group_error: false,
		refill_error_message: "",
		patient_notes: null,
		refill_patinet_notes: "",
		refill_upload_prescription_error: false,
		refill_upload_prescription: {},
		refill_upload_prescription_name: "",
		refill_request_submitted_by: "",
	};

	handleClickOpenModal = (scrollType) => () => {
		this.setState({ openModal: true, scroll: scrollType });
	};

	handleClickOpenRefillModal = (scrollType) => () => {
		// alert( scrollType );
		this.setState({ openRefillModal: true, scroll: scrollType });
	};

	handleCloseModal = () => {
		this.setState({ openModal: false });
	};

	handleCloseRefillModal = () => {
		this.setState({ openRefillModal: false });
	};

	isValidEmail = (email) => {
		//   var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		// ([^<>()[\]\\.,;:+\s@"]+(\.[^<>()[\]\\.,;:+\s@"]+)*)
		// [a-zA-Z0-9\-]+
		// [a-zA-Z0-9\-.]+   //--working
		var regex = /^(([^<>()[\]\\.,;:+\s@"]+(\.[^<>()[\]\\.,;:+\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	};

	handleNextModal = async () => {
		let direct_submit = true;

		// console.log("this.state.activeStep",this.state.activeStep);

		const newCompleted = this.state.completed;

		newCompleted[this.state.activeStep] = true;

		await this.setState({
			...this.state,
			isErrorInForm: false,
			first_name_error: false,
			broker_tpa_error: false,
			employer_group_error: false,
			email_error: false,
			email_validation_error: false,
			date_of_birth_error: false,
			drug_name_error: false,
			terms_error: false,
			upload_prescription_error: false,
		});

		// console.log("==========================" , this.state.first_name === "", this.state.first_name == "");
		// console.log(this.state.first_name, this.state.first_name.length );
		// console.log("==========================");
		if (this.state.activeStep === 0) {
			if (!this.state.first_name || this.state.first_name === "") {
				await this.setState({
					...this.state,
					first_name_error: true,
					isErrorInForm: true,
				});
			}

			if (!this.state.email || this.state.email === "") {
				await this.setState({
					...this.state,
					email_error: true,
					isErrorInForm: true,
				});
			}

			if (
				this.state.email !== "" &&
				!this.isValidEmail(this.state.email)
			) {
				// alert(1)
				await this.setState({
					...this.state,
					email_validation_error: true,
					isErrorInForm: true,
				});
			}

			if (!this.state.employer_group || this.state.employer_group === "") {
				await this.setState({
					...this.state,
					employer_group_error: true,
					isErrorInForm: true,
				});
			}

			if (!this.state.broker_tpa || this.state.broker_tpa === "") {
				await this.setState({
					...this.state,
					broker_tpa_error: true,
					isErrorInForm: true,
				});
			}

			if (!this.state.date_of_birth || this.state.date_of_birth === "") {
				await this.setState({
					...this.state,
					date_of_birth_error: true,
					isErrorInForm: true,
				});
			}

			// console.log("before condition",this.state.isErrorInForm)
			if (this.state.isErrorInForm) {
				// console.log(" don't move to next step")
			} else {
				// console.log(" move to next step")
				// direct_submit = false;
				await this.setState({
					newCompleted: newCompleted,
					prevActiveStep: this.state.prevActiveStep + 1,
					activeStep: this.state.activeStep + 1,
				});
			}
			// console.log("====>>>>>>>>>>>>>>>>>>>>>>>> if this.state.activeStep === 0 ", this.state.activeStep )
		} else {
			if (this.state.activeStep === 1) {
				// direct_submit = false;
				await this.setState({
					newCompleted: newCompleted,
					prevActiveStep: this.state.prevActiveStep + 1,
					activeStep: this.state.activeStep + 1,
				});
			}
			// console.log("====>>>>>>>>>>>>>>>>>>>>>>>>  this.state.activeStep === 0  else part ", this.state.activeStep )
		}

		if (this.state.activeStep === 2) {
			if (!this.state.drug_name || this.state.drug_name === "") {
				direct_submit = false;
				await this.setState({
					...this.state,
					drug_name_error: true,
					isErrorInForm: true,
				});
			}

			if (!this.state.terms) {
				direct_submit = false;
				await this.setState({
					...this.state,
					terms_error: true,
					isErrorInForm: true,
				});
			}
		}

		if (this.state.activeStep === 2 && direct_submit) {
			// alert(" submit")
			// console.log("submit");
			// return;

			// if( !this.state.drug_name || this.state.drug_name === "" ){

			// 	await this.setState( { ...this.state, drug_name_error: true, isErrorInForm: true  } );

			// } else {

			this.setState({ ...this.state, loadingMemberForm: true });

			let orderData = new FormData();

			orderData.append("first_name", this.state.first_name);
			orderData.append("last_name", this.state.last_name);
			orderData.append("member_id", this.state.member_id);
			orderData.append("person_code", this.state.person_code);
			orderData.append("email", this.state.email);
			orderData.append("mobile", this.state.mobile && this.state.mobile !== "" ? this.state.mobile.replace(/\D/g, "") : "");
			orderData.append("street", this.state.street);
			orderData.append("apartment_number", this.state.apartment_number);
			orderData.append("city", this.state.city);
			orderData.append("state", this.state.orderState);
			orderData.append("zipcode", this.state.zipcode);
			orderData.append("date_of_birth", this.state.date_of_birth_format);
			orderData.append("employer_group", this.state.employer_group);
			orderData.append("drug_name", this.state.drug_name);

			for (let i = 0; i < this.state.upload_prescription.length; i++) {
				orderData.append(
					"upload_prescription",
					this.state.upload_prescription[i],
				);
			}

			orderData.append("physician_name", this.state.physician_name);
			orderData.append(
				"physician_number",
				this.state.physician_number &&
					this.state.physician_number !== ""
					? this.state.physician_number.replace(/\D/g, "")
					: "",
			);
			orderData.append(
				"physician_fax_number",
				this.state.physician_fax_number &&
					this.state.physician_fax_number !== ""
					? this.state.physician_fax_number.replace(/\D/g, "")
					: "",
			);
			orderData.append("diagnosis", this.state.diagnosis);
			orderData.append("drug_allergies", this.state.drug_allergies);
			orderData.append(
				"drug_allergies_text",
				this.state.drug_allergies_text,
			);
			orderData.append("other_medications", this.state.other_medications);
			orderData.append(
				"other_medications_text",
				this.state.other_medications_text,
			);
			orderData.append("terms", this.state.terms ? "Yes" : "No");

			orderData.append("broker_tpa", this.state.broker_tpa);
			orderData.append("patient_notes", this.state.patient_notes);
			orderData.append("request_submitted_by", this.state.request_submitted_by ? this.state.request_submitted_by: "");

			// orderData.append('broker_tpa_id', this.state.broker_tpa_id);
			// orderData.append('broker_tpa_text', this.state.broker_tpa_text);

			// employer group removed
			// orderData.append('employer_group_id', this.state.employer_group_id);
			// orderData.append('employer_group_text', this.state.employer_group_text);

			// orderData.append('employer_group', this.state.employer_group);

			console.log("orderData", orderData);

			let userData = {
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				member_id: this.state.member_id,
				person_code: this.state.person_code,

				email: this.state.email,
				mobile: this.state.mobile && this.state.mobile !== ""
						? this.state.mobile.replace(/\D/g, "")
						: "",
				street: this.state.street,
				apartment_number: this.state.apartment_number,
				city: this.state.city,
				state: this.state.orderState,
				zipcode: this.state.zipcode,
				date_of_birth: this.state.date_of_birth_format,
				employer_group: this.state.employer_group,
			};
			// console.log("userData",userData);
			// console.log("orderData",{

			// 	'first_name': this.state.first_name,
			// 	'last_name': this.state.last_name,
			// 	'email': this.state.email,
			// 	'mobile': this.state.mobile,
			// 	'street': this.state.street,
			// 	'apartment_number': this.state.apartment_number,
			// 	'city': this.state.city,
			// 	'state': this.state.orderState,
			// 	'zipcode': this.state.zipcode,
			// 	'date_of_birth': this.state.date_of_birth_format,
			// 	'employer_group': this.state.employer_group,
			// 	'drug_name': this.state.drug_name,
			// 	'upload_prescription': this.state.upload_prescription,
			// 	'physician_name': this.state.physician_name,
			// 	'physician_number': this.state.physician_number,
			// 	'physician_fax_number': this.state.physician_fax_number,
			// 	'drug_allergies': this.state.drug_allergies,
			// 	'drug_allergies_text': this.state.drug_allergies_text,
			// 	'other_medications': this.state.other_medications,
			// 	'other_medications_text': this.state.other_medications_text,
			// 	'terms': (this.state.terms) ? 'Yes' : 'No',

			// });

			Axios.post("users/store", userData)
				.then((response) => {})
				.catch((error) => {
					//this.setState( { loading: false } );
					console.log("error of react - catch section");
					console.log(error);
					console.log("error of react - catch section");
				})
				.finally((res) => {
					Axios.post("orders/store", orderData)
						.then((response) => {
							this.setState({
								completed: [],
								activeStep: 0,
								openModal: false,
								openAlertDialog: true,

								first_name: "",
								last_name: "",
								member_id: "",
								person_code: "",
								email: "",
								mobile: "",
								street: "",
								apartment_number: "",
								city: "",
								orderState: "",
								zipcode: "",
								date_of_birth: null,
								date_of_birth_format: "",
								employer_group: "",
								drug_name: "",
								upload_prescription: [],
								upload_prescription_name: "",
								physician_name: "",
								physician_number: "",
								physician_fax_number: "",
								diagnosis: "",
								drug_allergies: "",
								drug_allergies_text: "",
								other_medications: "",
								other_medications_text: "",
								broker_tpa: "",
								broker_tpa_id: "",
								broker_tpa_text: "",
								employer_group_id: "",
								employer_group_text: "",
								patient_notes: "",
								terms: true,
							});
						})
						.catch((error) => {
							//this.setState( { loading: false } );
						})
						.finally((res) => {
							// console.log(res);
							// this.setState({...this.state, loadingMemberForm: true});
							this.setState({
								...this.state,
								loadingMemberForm: false,
							});
						});
				});

			// }
		}
	};

	handleSubmitRefill = async () => {
		let direct_submit = true;

		await this.setState({ loadingMemberForm: true });

		// console.log("this.state.activeStep",this.state.activeStep);

		const newCompleted = this.state.completed;

		newCompleted[this.state.activeStep] = true;

		await this.setState({
			...this.state,
			isErrorInForm: false,
			refill_email_error: false,
			refill_email_validation_error: false,
			refill_first_name_error: false,
			refill_date_of_birth_error: false,
			refill_drug_name_error: false,
			refill_terms_error: false,
			refill_employer_group_error: false,
			refill_error_message: ""
		});

		// console.log("==========================" , this.state.first_name === "", this.state.first_name == "");
		// console.log(this.state.first_name, this.state.first_name.length );
		// console.log("==========================");
		
		if (!this.state.refill_email || this.state.refill_email === "") {
			await this.setState({
				...this.state,
				refill_email_error: true,
				isErrorInForm: true,
			});
		}
		
		if (
			this.state.refill_email !== "" &&
			!this.isValidEmail(this.state.refill_email)
		) {
			await this.setState({
				...this.state,
				refill_email_validation_error: true,
				isErrorInForm: true,
			});
		}

		if (
			!this.state.refill_first_name ||
			this.state.refill_first_name === ""
		) {
			await this.setState({
				...this.state,
				refill_first_name_error: true,
				isErrorInForm: true,
			});
		}

		// console.log("this.state.refill_date_of_birth=====================");
		// console.log(this.state.refill_date_of_birth);

		if (
			!this.state.refill_date_of_birth ||
			this.state.refill_date_of_birth === ""
		) {
			await this.setState({
				...this.state,
				refill_date_of_birth_error: true,
				isErrorInForm: true,
			});
		}
		
		if (
			!this.state.refill_drug_name ||
			this.state.refill_drug_name === ""
		) {
			await this.setState({
				...this.state,
				refill_drug_name_error: true,
				isErrorInForm: true,
			});
		}
		
		if (
			!this.state.refill_employer_group ||
			this.state.refill_employer_group === ""
		) {
			await this.setState({
				...this.state,
				refill_employer_group_error: true,
				isErrorInForm: true,
			});
		}
		
		
		
		if ( this.state.refill_terms == "off" ) {
			this.setState({
				...this.state,
				refill_terms_error: true,
				isErrorInForm: true,
			});
		}
		
		if(this.state.isErrorInForm){
			this.setState({ loadingMemberForm: false });
			// let orderData = new FormData();

		} else {
			
			let RequestRefillData = new FormData();

			RequestRefillData.append("first_name", this.state.refill_first_name);
			RequestRefillData.append("last_name", this.state.refill_last_name);
			RequestRefillData.append("email", this.state.refill_email);
			RequestRefillData.append("date_of_birth", this.state.refill_date_of_birth_format);
			RequestRefillData.append("drug_name", this.state.refill_drug_name);
			RequestRefillData.append("employer_group", this.state.refill_employer_group);
			RequestRefillData.append("patient_notes", this.state.refill_patinet_notes);
			RequestRefillData.append("request_submitted_by", this.state.refill_request_submitted_by ? this.state.refill_request_submitted_by : "");

			for (let i = 0; i < this.state.refill_upload_prescription.length; i++) {
				RequestRefillData.append(
					"upload_prescription",
					this.state.refill_upload_prescription[i],
				);
			}

			// console.log("RequestRefillData==========");
			// console.log(RequestRefillData);
			
			Axios.post("orders/request-refill", RequestRefillData).then((response) => {

				// console.log(response.data);
				if( response.data.StatusCode == "0" ){
					console.log(111)
					this.setState({ refill_error_message: response.data.Message });
					
				} else {
					console.log(222)
					this.setState({

						openRefillModal: false,
						openAlertDialog: true,
						refill_first_name: '',
						refill_last_name: '',
						refill_email: '',
						refill_date_of_birth: null,
						refill_date_of_birth_format: new Intl.DateTimeFormat("en-US", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
						}).format(new Date()),
						refill_drug_name: '',
						refill_employer_group: '',
						refill_patinet_notes: '',
						refill_upload_prescription: {},
					});
					
				}
				
				
			}).catch((error) => {
				
				//this.setState( { loading: false } );
				
			}).finally((res) => {
				
				this.setState({ ...this.state, loadingMemberForm: false });

			});
			
		}
		
		// orderData.append("first_name", this.state.refill_first_name);
		// orderData.append("last_name", this.state.refill_last_name);
		// orderData.append("email", this.state.refill_email);
		// orderData.append("date_of_birth", this.state.refill_date_of_birth_format);
		// orderData.append("drug_name", this.state.refill_drug_name);
		
		// console.log("orderData", orderData);


		// Axios.post('users/store', userData)
		// 	.then(response => {
		// 	})
		// 	.catch(error => {
		// 		//this.setState( { loading: false } );
		// 		console.log("error of react - catch section");
		// 		console.log(error);
		// 		console.log("error of react - catch section");
		// 	})
		// .finally(res => {
			
			
		

		// });
	};

	handleBackModal = () => {
		// this.setState({ activeStep: this.state.activeStep - 1, prevActiveStep: this.state.prevActiveStep - 1 });
		this.setState({ activeStep: 0, prevActiveStep: 1 });
	};
	handleResetModal = () => {
		this.setState({ completed: [], activeStep: 0 });
	};
	handleAlertDialogOpen = () => {
		this.setState({ openAlertDialog: true });
	};
	handleAlertDialogClose = () => {
		this.setState({ openAlertDialog: false });
	};
	onInputchange = (event, selectedNewValue) => {
		// console.log("==============event.target.name",event.target.name);
		// console.log("==============event.target.name",event.target.value);
		// console.log("==============event.target.id",event.target.id);
		if (
			event &&
			event.target &&
			event.target.id &&
			event.target.id.startsWith("oemployer-group")
		) {
			console.log("find id", selectedNewValue.title);

			this.setState({
				employer_group: selectedNewValue
					? selectedNewValue.title
					: null,
				employer_group_id: selectedNewValue
					? selectedNewValue.value
					: null,
				employer_group_text: selectedNewValue
					? selectedNewValue.title
					: null,
			});
		} else if (
			false &&
			event &&
			event.target &&
			event.target.id &&
			(event.target.id.startsWith("broker_tpa") ||
				event.target.id === "broker-tpa-clear")
		) {
			console.log("find id", selectedNewValue);

			if (selectedNewValue) {
				this.setState({
					broker_tpa_id: selectedNewValue
						? selectedNewValue.value
						: null,
					broker_tpa_text: selectedNewValue
						? selectedNewValue.title
						: null,
				});
			} else {
				this.setState({
					broker_tpa_id: null,
					broker_tpa_text: null,
				});
			}
		} else {
			var eventstring = event.toString();
			if (
				eventstring !== "Invalid Date" &&
				(event === undefined || event.target === undefined)
			) {

				// console.log("eventstring================================");
				// console.log("eventstring",eventstring);

				// console.log("selectedNewValue================================");
				// console.log(selectedNewValue);

				// console.log("event================================");
				// console.log(event);
				
				var dateformate = new Intl.DateTimeFormat("en-US", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}).format(event);
				// console.log("dateformate", dateformate, event);
				this.setState({
					date_of_birth: event,
					date_of_birth_format: new Intl.DateTimeFormat("en-US", {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
					}).format(event),
				});
			} else {
				if (eventstring !== "Invalid Date") {
					if (event.target.name === "terms") {
						this.setState({
							[event.target.name]: !this.state.terms,
						});
					} else if (event.target.name === "upload_prescription") {
						this.setState({ upload_prescription_error: false });
						// console.log("event.target.files", event.target.files);
						// if(event.target.files[0].size > 2000000){

						let isUploadError = false;
						let lengthFlag = event.target.files.length;
						for (let i = 0; i < event.target.files.length; i++) {
							lengthFlag--;
							const file = event.target.files[i];
							// console.log(file.size);

							if (file.size >= 5242880) {
								// maximum file size of 5MB
								isUploadError = true;
							}

							if (lengthFlag === 0) {
							}
						}
						// console.log(
						// 	"isUploadError>>>>>>>>>>>>",
						// 	isUploadError,
						// 	event.target.files.length > 5,
						// );

						if (event.target.files.length > 5 || isUploadError) {
							event.preventDefault();

							this.setState({
								upload_prescription_error: true,
								upload_prescription: [],
							});
						} else {
							this.setState({
								[event.target.name]: event.target.files,
								upload_prescription_name:
									event.target.files[0].name,
								upload_prescription_error: false,
							});
						}
					} else if (event.target.name === "refill_upload_prescription") {
						this.setState({ refill_upload_prescription_error: false });
						// console.log("event.target.files", event.target.files);
						// if(event.target.files[0].size > 2000000){

						let isUploadError = false;
						let lengthFlag = event.target.files.length;
						for (let i = 0; i < event.target.files.length; i++) {
							lengthFlag--;
							const file = event.target.files[i];
							// console.log(file.size);

							if (file.size >= 5242880) {
								// maximum file size of 5MB
								isUploadError = true;
							}

							if (lengthFlag === 0) {
							}
						}
						// console.log(
						// 	"isUploadError>>>>>>>>>>>>",
						// 	isUploadError,
						// 	event.target.files.length > 5,
						// );

						if (event.target.files.length > 5 || isUploadError) {
							event.preventDefault();

							this.setState({
								refill_upload_prescription_error: true,
								refill_upload_prescription: [],
							});
						} else {
							this.setState({
								[event.target.name]: event.target.files,
								refill_upload_prescription_name: event.target.files[0].name,
								refill_upload_prescription_error: false,
							});
						}
					} else {
						this.setState({
							[event.target.name]: event.target.value,
						});
					}
				}
			}
		}
	};

	
	onInputchangeRefillDOB = (event, selectedNewValue) => {
		// console.log("==============event.target.name",event.target.name);
		// console.log("==============event.target.id",event.target.id);

		// console.log("onInputchangeRefillDOB ======= onchange )(*&)(*&)(*&*)(&*())*");
		// console.log(event);

		var eventstring = event.toString();
		if(event !== "" && eventstring !== "Invalid Date" && (event === undefined || event.target === undefined)){
			// console.log("one")
			// var dateformate = new Intl.DateTimeFormat("en-US", {year: "numeric",month: "2-digit",day: "2-digit"}).format(event);
			// console.log("dateformate", dateformate, event);
			this.setState({
				refill_date_of_birth: event,
				refill_date_of_birth_format: new Intl.DateTimeFormat("en-US", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}).format(event),
			});
		} else {
			console.log("two")
			this.setState({
				refill_date_of_birth: null,
				refill_date_of_birth_format: new Intl.DateTimeFormat("en-US", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}).format(new Date())
			});
		}

		// console.log("eventstring================================");
		// console.log("eventstring",eventstring);

		// console.log("selectedNewValue================================");
		// console.log(selectedNewValue);

		// console.log("event================================");
		// console.log(event);
		
		
	};

	render() {
		// console.log( "render this.state.loadingMemberForm ==> ", this.state.loadingMemberForm);
		return (
			// <BrowserRouter basename={'/rxfree4me/'} >

			<ThemeProvider theme={theme}>
				{/* <Router > */}
				<Navbar
					openModal={this.state.openModal}
					handleClickOpenModal={() => this.handleClickOpenModal()}
					handleCloseModal={() => this.handleCloseModal()}
					handleNextModal={() => this.handleNextModal()}
					handleBackModal={() => this.handleBackModal()}
					handleResetModal={() => this.handleResetModal()}
					openAlertDialog={this.state.openAlertDialog}
					handleAlertDialogClose={() => this.handleAlertDialogClose()}
					onInputchange={(e) => this.onInputchange(e)}
					prevActiveStep={this.state.prevActiveStep}
					activeStep={this.state.activeStep}
					completed={this.state.completed}
					scroll={this.state.scroll}
					state={this.state}
					buttanEnable={false}
					{...this.props}
				/>

				<Routes>
					<Route
						exact
						path='/'
						element={
							<Home
								open={this.state.openModal}
								openRefillModal={this.state.openRefillModal}
								handleClickOpenModal={() =>
									this.handleClickOpenModal()
								}
								handleClickOpenRefillModal={() =>
									this.handleClickOpenRefillModal()
								}
								handleCloseModal={() => this.handleCloseModal()}
								handleCloseRefillModal={() =>
									this.handleCloseRefillModal()
								}
								handleNextModal={() => this.handleNextModal()}
								handleBackModal={() => this.handleBackModal()}
								handleSubmitRefill={() =>
									this.handleSubmitRefill()
								}
								handleResetModal={() => this.handleResetModal()}
								openAlertDialog={this.state.openAlertDialog}
								handleAlertDialogOpen={() =>
									this.handleAlertDialogOpen()
								}
								handleAlertDialogClose={() =>
									this.handleAlertDialogClose()
								}
								onInputchange={(e, a) =>
									this.onInputchange(e, a)
								}
								onInputchangeRefillDOB={(e, a) =>
									this.onInputchangeRefillDOB(e, a)
								}

								prevActiveStep={this.state.prevActiveStep}
								activeStep={this.state.activeStep}
								completed={this.state.completed}
								scroll={this.state.scroll}
								state={this.state}
								loadingMemberForm={this.state.loadingMemberForm}
								buttanEnable={true}
							/>
						}
					/>

					<Route
						exact
						path='/terms-and-conditions'
						element={<TermsAndConditions />}
					/>

					<Route
						exact
						path='/member-rights'
						element={<MemberRights />}
					/>
				</Routes>

				<Footer />
				{/* </Router> */}
			</ThemeProvider>
		);
	}
}

export default App;
