import React, { useRef } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import './Navbar.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
//import Button from '@mui/material/Button';
import Logo from '../../logo.png';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Modal from '../../components/Modal/Modal';
import * as Scroll from 'react-scroll';
import { Link, animateScroll as scroll } from "react-scroll";

import { Link as ReactRLink } from "react-router-dom";


const pages = ['Home', 'About Us', 'Services', 'Member', 'Why Choose Us', 'Contact Us'];
const pageKeys = ['home', 'who-we-are', 'our-services', 'member', 'why-choose-us', 'contact-us'];

// const Navbar = (props, anchor) => {
const Navbar = (props) => {
  const location = useLocation();
  // useNavigate
  // console.log("location", location.pathname);

  // console.log("first--------------------anchor");
  // console.log(anchor);
  // console.log("first--------------------anchor");

  const [openModal, setOpenModal] = React.useState(false);
  let scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {

    // console.log("***********************************************");
    // console.log("====================anchor====================");
    // console.log(anchor);
    // console.log("====================state====================");
    // console.log(state);
    // console.log("***********************************************");

    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // console.log("==================props.openModal==============")
  // console.log(props.openModal)
  // console.log("==================props.openModal==============")
  // console.log(anchor)
  // console.log(props);
  return (
    <>
      <AppBar position="sticky" elevation={0} sx={{ bgcolor: 'primary.light', py: 1 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

              {
                (location.pathname !== "/terms-and-conditions") ?  

                <Link onClick={scrollToTop} to='/'>
                  <img
                    src={Logo}
                    srcSet={Logo}
                    alt="RxFree4me-logo"
                    loading="lazy"
                    className='logo'
                  />
                </Link> : 
                <ReactRLink onClick={scrollToTop} to='/'>
                  <img
                    src={Logo}
                    srcSet={Logo}
                    alt="RxFree4me-logo"
                    loading="lazy"
                    className='logo'
                  />
                </ReactRLink> 
              }
              
              
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer("left", true)}
                sx={{ color: 'black' }}
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor='left'
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {
                  (location.pathname !== "/terms-and-conditions") ?
                <Box sx={{ typography: 'body2', py: 2 }}>
                  
                  {

                    pages.map((page, index) => {

                      return (

                        (page === "Member") ?
                          <Link
                            key={page}
                            component="button"
                            variant="contained"
                            className="link-item"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            to={"#"}
                            onClick={props.handleClickOpenModal('paper')}
                          >
                            {page}
                          </Link>
                          :
                          <Link
                            key={page}
                            component="button"
                            variant="contained"
                            className="link-item m-link-item"
                            to={pageKeys[index]}
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            onClick={toggleDrawer("left", false)}
                          >
                            {page}
                          </Link>
                      )
                    }
                  )}
                </Box>
                
                :null }
              </SwipeableDrawer>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

              {
                (location.pathname !== "/terms-and-conditions") ?  

                  <Link onClick={scrollToTop} to='/'>
                    <img
                      src={Logo}
                      srcSet={Logo}
                      alt="RxFree4me-logo"
                      loading="lazy"
                      className='logo'
                    />
                  </Link>
                : 
                  <ReactRLink  to='/'>
                    <img
                      src={Logo}
                      srcSet={Logo}
                      alt="RxFree4me-logo"
                      loading="lazy"
                      className='logo'
                    />
                  </ReactRLink>
                  
              }
            </Box>

            
            {
              (location.pathname !== "/terms-and-conditions") ?

              <Box sx={{ display: { xs: 'none', md: 'flex' }, typography: 'body2' }}>
                {
                  pages.map((page, index) => {
                    return (
                      (page === "Member") ?
                        <Link
                          key={page}
                          component="button"
                          variant="contained"
                          className="link-item"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          to={"#"}
                          onClick={props.handleClickOpenModal('paper')}
                        >
                          {page}
                        </Link>
                        :
                        <Link
                          key={page}
                          component="button"
                          variant="contained"
                          className="link-item"
                          to={pageKeys[index]}
                          spy={true}
                          hashSpy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          onClick={toggleDrawer("left", false)}
                          pagedata={pageKeys[index]}
                        >
                          {page}
                        </Link>
                    )
                  }
                  )}
              </Box>

              : 
              <Box sx={{ display: { xs: 'none', md: 'flex' }, typography: 'body2' }}>
                {
                  pages.map((page, index) => {
                    return (
                      (page === "Member") ?
                        <Link
                          key={page}
                          component="button"
                          variant="contained"
                          className="link-item"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                          to={"#"}
                          onClick={props.handleClickOpenModal('paper')}
                        >
                          {page}
                        </Link>
                        :
                        <a
                          className="link-item"

                          href={"/#"+pageKeys[index]}

                          // key={page}
                          // component="button"
                          // variant="contained"
                          // className="link-item"
                          // // to={"#"+pageKeys[index]}
                          // to={{
                          //   pathname: "",
                          //   hash: "#"+pageKeys[index],
                          // }}
                          // // onClick={toggleDrawer("left", false)}
                        >
                          {page}
                        </a>
                    )
                  }
                  )}
              </Box>

            }

          </Toolbar>
        </Container>
      </AppBar>
      <Modal open={props.openModal}
        handleClickOpen={props.handleClickOpenModal}
        handleClose={props.handleCloseModal}
        handleNext={props.handleNextModal}
        handleBack={props.handleBackModal}
        handleReset={props.handleResetModal}
        openAlertDialog={props.openAlertDialog}
        handleAlertDialogClose={props.handleAlertDialogClose}
        onInputchange={props.onInputchange}
        prevActiveStep={props.prevActiveStep}
        activeStep={props.activeStep}
        completed={props.completed}
        scroll={props.scroll}
        state={props.state}
        buttanEnable={props.buttanEnable}
      />
    </>

  );
};
export default Navbar;