import * as React from 'react';
import { useEffect, useState } from "react";
import './Footer.css';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  return (
    <>
      <Box className="footer" sx={{ bgcolor: 'primary.light', py: 1 }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ flexWrap: 'wrap' }}>
            <Typography
              variant='body2'
              component="div"
              sx={{ pr: 2, flexGrow: 1 }}
            >
              &copy; 2023 RxFree4me. All rights reserved.
            </Typography>
            <Box
              sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}
              spacing={2}
            >
              <Typography variant='body2'>
                <a href='terms-and-conditions' target={"_blank"}>Privacy &amp; Term of Use</a>
              </Typography>
              <Divider orientation="vertical" variant="middle" flexItem  sx={{ mx: 1 }}/>
              <Typography variant='body2'>
                <a href='member-rights' target={"_blank"}>Member Rights</a>
                
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </Box>
      {showButton && (
        <Button color="primary" variant="contained" disableElevation sx={{mt: 4, borderRadius: '25px'}} onClick={scrollToTop}className="back-to-top"><ExpandLessIcon /></Button>
			)}
    </>
  );
};
export default Footer;