import * as React from 'react';
import './Modal.css'
// import Box from '@mui/material/Box';
import { Grid, TextField, Box, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';

//import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
// import BasicInfoForm from '../../components/Forms/BasicInfoForm';
// import PrescriptionForm from '../../components/Forms/PrescriptionForm';

import AlertDialog from '../../components/Dialog/AlertDialog';
// import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const RefillModal = (props) => {

	const descriptionElementRef = React.useRef(null);

	
	const dobRef = React.useRef();
	
	// React.useEffect(() => {

	// 	function applyRefBind() {
	// 		console.log("dobRef");
	// 		console.log(dobRef);
			
	// 		dobRef.current.addEventListener('input', handleInputChange);
			
			
	// 	}
		
	// 	setTimeout(() => {
	// 		applyRefBind();
	// 	}, 1000);
	// 	return () => {
	// 		dobRef.current.removeEventListener('input', handleInputChange);
	// 	};
	// }, []);

	const handleInputChange = (event) => {
		// console.log("on input event listner");
		// console.log(event.target.value);
		if(event.target.value == "")
		props.onInputchangeRefillDOB( "" )
	}
	
	React.useEffect(() => {
		function applyRefBind() {
			// console.log("dobRef");
			// console.log(dobRef);
			
			dobRef.current.addEventListener('input', handleInputChange);
			
			
		}
		if (props.openRefillModal) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}

			
			
			setTimeout(() => {
				applyRefBind();
			}, 1000);
			
			
		}

		// return () => {
		// 	dobRef.current.removeEventListener('input', handleInputChange);
		// };
		
	}, [props.openRefillModal]);

	
	const handleDateChange = (newValue) => {

		if(newValue)
			props.onInputchange( newValue );
	};

	const handleRefillDateChange = (newValue) => {

		if(newValue)
			props.onInputchangeRefillDOB( newValue );
	};
	
	
	// const [value, setValue] = React.useState(new Date(''));
	// const handleChange = (newValue) => {
	// 	setValue(newValue);
	// };
	const Input = styled('input')({
		display: 'none',
	});
	const [state] = React.useState({
		terms: true
	});
	const { terms } = state;

	const LinkCustom = <a href='terms-and-conditions' target={"_blank"} >Terms and Conditions</a>;

	// console.log("props.buttanEnable====================");
	// console.log(props.buttanEnable);
	
	return (
    <>
	
		{
			( props.buttanEnable === true ) ? 
				<Button style={{marginLeft: "15px"}} onClick={props.handleClickOpenRefillModal('paper')} color="primary" variant="contained" disableElevation sx={{mt: 4, borderRadius: '25px'}} /*data-aos="fade-up"*/ >Request Refill</Button>
				: 
				null 
		}
		
		<Dialog
			open={props.openRefillModal}
			onClose={props.handleClose}
			scroll={props.scroll}
			// fullScreen
			maxWidth="lg"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			TransitionComponent={Transition}
		>
			<DialogTitle id="scroll-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
				Request Refill
				{ props.handleClose ? (
					<IconButton
						aria-label="close"
						onClick={props.handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.primary.main,
						}}
					>
						<CloseIcon />
					</IconButton>
				) : null }
			</DialogTitle>
			<DialogContent dividers={props.scroll === 'paper'} style={{paddingTop: "20px" }}>
				<DialogContentText
					id="scroll-dialog-description"
					ref={descriptionElementRef}
					tabIndex={-1}
					position="relative"
					component="div"
				>

					<Box component="form" noValidate autoComplete="off" >
						
						<Grid container spacing={2}>

									
							<Grid item md={4} sm={6} xs={12}>
								<TextField
								fullWidth
								error={(props.state.refill_email_error || props.state.refill_email_validation_error ) ? true : false}
								name="refill_email"
								id="refill_email"
								label="Email"
								helperText={props.state.refill_email_error ? "This field is required" : props.state.refill_email_validation_error ? "Please enter a valid email address" : "" }
								onChange={props.onInputchange}
								value={props.state.refill_email}
								/>
							</Grid>
							
							<Grid item md={4} sm={6} xs={12} >
								<TextField
									error={(props.state.refill_first_name_error) ? props.state.refill_first_name_error : false}
									id="refill_first_name"
									name="refill_first_name"
									label="First Name"
									helperText={props.state.refill_first_name_error ? "This field is required" : ""}
									fullWidth
									onInput = {(e) =>{
										e.target.value = e.target.value.slice(0,25);
									}}
									onBlur={(e) => {
										e.target.value = e.target.value.trim();
										props.onInputchange(e)
									}}
									onChange={props.onInputchange}
									value={props.state.refill_first_name}
								/>
							</Grid>
							
							<Grid item md={4} sm={6} xs={12}>
								<TextField
									id="refill_last_name"
									name="refill_last_name"
									label="Last Name"
									helperText=" "
									fullWidth
									onInput = {(e) =>{
										e.target.value = e.target.value.slice(0,25);
									}}
									onBlur={(e) => {
										e.target.value = e.target.value.trim();
										props.onInputchange(e)
									}}
									onChange={props.onInputchange}
									value={props.state.refill_last_name}
								/>
							</Grid>
							
							<Grid item md={4} sm={6} xs={12}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										label="Date of Birth"
										name="refill_date_of_birth"
										// inputFormat="MM/dd/yyyy"
										value={props.state.refill_date_of_birth}
										//value={value}
										inputRef={dobRef}
										// field={(val) => {
										// 	console.log("field")
										// 	console.log(val)
										// }}
										onInput={(e)=> {
											// console.log("onInput is working")
											// console.log(e)
										}}
										
										onChange={handleRefillDateChange}
										disableFuture
										// onChange={(value) => props.onInputchange(value)}
										renderInput={
											(params) => <TextField 
													{...params}
													error={(props.state.refill_date_of_birth_error) ? true : false}
													sx={{width: '100%'}}
													helperText={(props.state.refill_date_of_birth_error) ? "This field is required" : "" }
												/>
										}
									/>
								</LocalizationProvider>
							</Grid>

							<Grid item md={4} sm={6} xs={12}>

								<TextField
									id="refill_drug_name"
									name="refill_drug_name"

									error={(props.state.refill_drug_name_error ) ? true : false}
									helperText={props.state.refill_drug_name_error ? "This field is required" : ""}

									
									label="Drug"
									fullWidth
									onChange={props.onInputchange}
									value={props.state.refill_drug_name}
								/>

							</Grid>

							<Grid item md={4} sm={6} xs={12}>

								<TextField
									id="refill_employer_group"
									name="refill_employer_group"

									// error={(props.state.refill_employer_group_error ) ? true : false}
									// helperText={props.state.refill_employer_group_error ? "This field is required" : ""}

									label="Employer Name"
									error={(props.state.refill_employer_group_error) ? true : false}
									helperText={props.state.refill_employer_group_error ? "This field is required" : ""}
									fullWidth
									onChange={props.onInputchange}
									value={props.state.refill_employer_group}
								/>

							</Grid>

							<Grid item md={4} sm={6} xs={12}>

								<TextField
									id="refill_patinet_notes"
									name="refill_patinet_notes"

									// error={(props.state.refill_employer_group_error ) ? true : false}
									// helperText={props.state.refill_employer_group_error ? "This field is required" : ""}

									label="Notes"
									helperText=""
									fullWidth
									onChange={props.onInputchange}
									value={props.state.refill_patinet_notes}
								/>

							</Grid>

							<Grid item md={4} sm={6} xs={12}>
								<FormControl variant="outlined" sx={{width: '100%', mb:3}}>
									<InputLabel error={props.state.refill_upload_prescription_error} htmlFor="outlined-adornment-prescription">Upload Prescription</InputLabel>
									<OutlinedInput
										error={props.state.refill_upload_prescription_error}
										id="outlined-adornment-prescription"
										// type={values.prescription ? 'text' : 'prescription'}
										value={props.state.refill_upload_prescription.length ? `${props.state.refill_upload_prescription.length} selected` : "0 selected"}
										//onChange={props.onInputchange}
										endAdornment={
											<InputAdornment position="end">
												<label htmlFor="icon-button-file">
														<Input name="refill_upload_prescription" accept=".jpg,.jpeg,.png,.pdf" multiple id="icon-button-file" type="file" onChange={props.onInputchange} />
														<IconButton aria-label="upload file" component="span" >
															<FileUploadOutlinedIcon />
														</IconButton>
												</label>
											</InputAdornment>
										}
										label="Upload Prescription"

									/>
									<small className='Mui-error' style={{fontSize:"0.8571428571428571rem", color: props.state.refill_upload_prescription_error ? "#d32f2f" : null}}>The Max file size limit is 5MB for each and only JPG, JPEG, PNG & PDF are allowed. You can only upload a maximum of 5 files.</small>
								</FormControl>
							</Grid>

							<Grid item md={4} sm={6} xs={12} >
								<TextField
									id="refill_request_submitted_by"
									name="refill_request_submitted_by"
									label="Request submitted by"
									fullWidth
									onInput = {(e) =>{
										e.target.value = e.target.value.slice(0,30);
									}}
									onBlur={(e) => {
										e.target.value = e.target.value.trim();
										props.onInputchange(e)
									}}
									onChange={props.onInputchange}
									value={props.state.refill_request_submitted_by}
								/>
							</Grid>
							
						</Grid>
						
						<Grid item md={12} sm={12} xs={12} mt={3}>
							
							<Checkbox checked={props.state.refill_terms == "on" ? true: false} 
								onChange={props.onInputchange} value={props.state.refill_terms == "off" ? "on" : "off"} name="refill_terms" 
								style={props.state.refill_terms_error && props.state.refill_terms == "off" ? { color: "red" } : null }
							/>

							{/* I have read and agree to RxFree4Me's  {LinkCustom}, including that the medication may come from Canada. */}
							I have read and agree to RxFree4Me's {LinkCustom}.
							
						</Grid>
						
					</Box>

				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{padding: '20px 24px'}}>
				{/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex', pt: 2 }}> */}
					{ 
					(props.state.refill_error_message) ? 
						<Box sx={{ color: 'error.main' }}>{props.state.refill_error_message}</Box> : null
					}
					<LoadingButton 
						color="primary" 
						onClick={props.handleSubmitRefill} 
						loading={ props.loadingMemberForm } 
						sx={{borderRadius: '25px'}} 
						variant="contained" 
					>
						Submit
					</LoadingButton>
				{/* </Box> */}
			</DialogActions>
		</Dialog>
		
		<AlertDialog openAlertDialog={props.openAlertDialog}  handleAlertDialogClose={props.handleAlertDialogClose} message = "Your request has been sent successfully. An RxFree4Me representative will be in contact with you shortly to explain the next steps. Thank you for enrolling." />
    </>
  );
}

export default RefillModal;