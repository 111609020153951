import * as React from 'react';
import './TermsAndConditions.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

class TermsAndConditions extends React.Component {

	render() {
		const questionList = [
			{description: "The patient wishes to order their medication from, and have their order filled by a licensed pharmacy in the United States and/or Canada.", key: 0},
			{description: "The patient has been examined with in the last 12 months and has received a lawfully prescribed prescription from a physician licensed to practice medicine within the patient's home jurisdiction. The patient is not seeking medical advice or relying on medical information from RXFREE4ME or their agents.", key: 1},
			{description: "The patient’s consents to RXFREE4ME and their agent physician being able to contact the patient’s physician, who issued the prescription, as it pertains to prescribing and dispensing of their medications. The patient understands that the reason for this consent is to provide each agent physician and RXFREE4ME with the opportunity to conduct an independent analysis of whether the prescription obtained is suitable and to discuss any medical conditions that may arise.", key: 2},
			{description: "The prescription has not been altered in any way, nor has it been filled prior to submission to RXFREE4ME.", key: 3},
			{description: "The patient will immediately contact their physician who prescribed their medication, if they suffer any unexpected side effects from medications ordered from RXFREE4ME.", key: 4},
			{description: "The patient understands that the medications are sold, dispensed and delivered within the jurisdiction of where the dispensing pharmacy operates. In the case of RXFREE4ME, this jurisdiction is may be the United States or Canada. The patient understands that they are the one shipping the medication, not RXFREE4ME.", key: 5},
			{description: "The patient agrees to use the medication ordered through RXFREE4ME, according to the instructions stated by the physician who provided the prescription, at the patient’s home jurisdiction. The patient will not allow anyone else to use their medications.", key: 6},
			{description: "The patient has fully and accurately disclosed their personal and health information and authorizes RXFREE4ME to collect and use the information for the processing and delivery of the orders placed by the patient.", key: 7},
			{description: "The patient grants to RXFREE4ME limited power of attorney to take all steps, sign all documents, and act on the patient's behalf for the purposes of obtaining an equivalent prescription recognized and valid within the dispensing pharmacy's jurisdiction. This would be the same steps that the patient would perform if they were present in the pharmacy’s jurisdiction. This shall include but not limited to collecting and using the patient’s personal and personal health information as is needed to process their order. This shall continue until the patient revokes permission, which can be done at any time.", key: 8},
			{description: "The patient adheres to the jurisdiction of the dispensing pharmacy's operations. All agreements reached or contracts formed will be made in the jurisdiction of the pharmacy, the laws of the jurisdiction shall govern all transactions, and the courts in the jurisdiction of the pharmacy shall be sole and exclusive authority regarding any dispute arising between the patient and the dispensing pharmacy.", key: 9},
			{description: "The patient agrees to notify the dispensing pharmacy, in writing, of any changes in their drug therapy 20 days prior to medication being shipped.", key: 10},
			{description: "The patient understands that they accept responsibility for shipping and receiving their medication. The patient accepts that there may be delays, such as customs holding the package, and that RXFREE4ME has no control over unexpected delays. RXFREE4ME takes all necessary precautions to pack your items in a safe and reliable manner. The patient agrees that they are responsible having someone to sign and accept shipments that requires a signature on delivery. RXFREE4ME is not responsible for shipments that arrived, and no one was available to sign for delivery.", key: 11},
			{description: "The patient agrees that, by providing their mobile phone number via the enrollment form and/or to RxFREE4ME by calling/texting our business phone number, they are opting in to receive text messages from our business to receive informational text messages from us. Message frequency may vary. Message and data rates may apply. You can unsubscribe from receiving text messages at any time by emailing info@rxfree4me.com. Please note that opting out may impact your ability to receive updates and notifications from us.", key: 12},
		  ];
		return (
			<Box sx={{py: 5}} id="terms-of-use">
				<Container maxWidth="lg">
					<Grid container>
						<Grid item md={12} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
							<Typography
								variant="h2"
								component="h1"             
								sx={{mb: 4}}
							>
							Terms of Use
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							RXFREE4ME is a pharmacy consulting company that specializes in facilitating medications at a lower cost for your employer.<br/>
							In addition, the following regulations, term and conditions governs all sales between RXFREE4ME, and the patient. Clause XIII below applies regardless of whether a sales relationship exists and shall apply when a person calls or texts any toll-free business line maintained by RXFREE4ME.<br/>
							The patient is of the age of majority and legally entitled to purchase and receive the medications requested of RXFREE4ME and its Partners or Agents, and:
							</Typography>
							<ol type="I">
								{questionList.map(question => {
								return (
									<li key={question.key}>{question.description}</li>
								);
								})}
							</ol>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							I HAVE READ AND UNDERSTOOD THESE TERMS AND CONDITIONS SET OUT ABOVE AND AGREE THAT THEY SHALL BE BINDING UPON ME AND MY ASSIGNS, HEIRS AND PERSONAL REPRESENTATIVES.<br/>
							OR. “I am the parent/legal guardian/power of attorney for the Patient disclosed herein, am over the age of majority, and have full authority to sign for and provide the above representations to the Pharmacy on the Patient's behalf<br/>
							Electronically Signed<br/>

							<b>By</b><br/>
							<b>Date</b>: 07/23/2024
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}
}
export default TermsAndConditions;