import * as React from 'react';
import './MemberRights.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListSubheader, ListItemText } from '@mui/material';

class MemberRights extends React.Component {

	render() {
		const questionList = [
			{description: "The patient wishes to order theirmedication from, and have their order filled by a licensed pharmacy in the United States and/or Canada.", key: 0},
			{description: "The patient has been examined with in the last 12 months and has received a lawfully prescribed prescription from a physician licensed to practice medicine within the patient's home jurisdiction. The patient is not seeking medical advice or relying onmedical information from RXFREE4MEor their agents.", key: 1},
			{description: "The patient’s consents to RXFREE4MEand theiragent physician being able to contact the patient’s physician, who issued the prescription, as it pertains to prescribing and dispensing of their medications. The patient understands that the reason for this consent is to provide each agent physician and RXFREE4MEwith the opportunity to conduct an independent analysis of whether the prescription obtained is suitable and to discuss any medical conditions that may arise.", key: 2},
			{description: "The prescription has not been altered in any way, nor has it been filled prior to submission to RXFREE4ME.", key: 3},
			{description: "The patient will immediately contact their physician who prescribed their medication, if they suffer any unexpected s,ide effects from medications ordered from RXFREE4ME.", key: 4},
			{description: "The patient understands that the medications are sold, dispensed and delivered within the jurisdiction of where the dispensing pharmacy operates. In the case of RXFREE4ME, this jurisdiction is may be the United States or Canada. The patient understands that they are the one shipping the medication, not RXFREE4ME.", key: 5},
			{description: "The patient agrees to use the medication ordered through RXFREE4ME, according to the instructions stated by the physician who provided the prescription, at the patient’s home jurisdiction. The patient will not allow anyone else to use their medications.", key: 6},
			{description: "The patient has fully and accurately disclosed their personal and health information and authorizes RXFREE4MEto collect and use the information for the processing and delivery of the orders placed by the patient.", key: 7},
			{description: "The patient grants to RXFREE4MElimited power of attorney to take all steps, sign all documents, and act on the patient's behalf for the purposes of obtaining an equivalent prescription recognized and valid within the dispensing pharmacy's jurisdiction. This would be the same steps that the patient would perform if they were present in the pharmacy’s jurisdiction. This shall include but not limited to collecting and using the patient’s personal and personal health information as is needed to process their order. This shall continueuntil the patient revokes permission, which can be done at any time.", key: 8},
			{description: "The patient adheres to the jurisdiction of the dispensing pharmacy's operations. All agreements reached or contracts formed will be made in the jurisdiction of the pharmacy, the laws of the jurisdiction shall govern all transactions, and the courts in the jurisdiction of the pharmacy shall be sole and exclusive authority regarding any dispute arising between the patient and the dispensing pharmacy.", key: 9},
			{description: "The patient agrees to notifythe dispensing pharmacy, in writing, of any changes in their drug therapy 20 days prior to medication being shipped.", key: 10},
			{description: "The patient understands that they accept responsibility for shipping and receiving their medication. The patient acceptsthat there may be delays, such as customs holding the package, and that RXFREE4MEhas no control over unexpected delays. RXFREE4MEtakes all necessary precautions to pack your items in a safe and reliable manner. The patient agrees that they are responsible having someone to sign and accept shipments that requires a signature on delivery. RXFREE4MEis not responsible for shipments that arrived, and no one was available to sign for delivery.", key: 11}
		  ];
		return (
			<Box sx={{py: 5}} id="terms-of-use">
				<Container maxWidth="lg">
					<Grid container>
						<Grid item md={12} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
							<Typography
								variant="h2"
								component="h1"             
								sx={{mb: 4}}
							>
							Member rights
							</Typography>
							<Typography
								variant="h3"
								component="h2"             
								sx={{mb: 2}}
							>
							Your Rights. Our Responsibilities.
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully.
							</Typography>
							<Typography
								variant="h3"
								component="h3"
								color="secondary"             
								sx={{mt:4, mb: 2}}
							>
							Your Rights
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.
							</Typography>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Get an electronic or paper copy of your medical record
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do this.
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Ask us to correct your medical record 
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
											variant="body2"
											>
											•	You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
											variant="body2"
											>
											•	We may say “no” to your request, but we’ll tell you why in writing within 60 days.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Request confidential communications
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
											variant="body2"
											>
											•	You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
											variant="body2"
											>
											•	We will say “yes” to all reasonable requests.
											</Typography>
										</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Ask us to limit what we use or share
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
											variant="body2"
											>
											•	You can ask us not to use or share certain health information for treatment, payment, or our operations.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
										<Typography
										variant="body2"
										>
										•	We are not required to agree to your request, and we may say “no” if it would affect your care.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	We will say “yes” unless a law requires us to share that information.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Get a list of those with whom we’ve shared information
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<ListItemText>
									<Typography
										variant="body2"
										>
										•	You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.
									</Typography></ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable, cost- based fee if you ask for another one within 12 months.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Get a copy of this privacy notice
									</Typography>
								</ListSubheader>
								<ListItem disableGutters><ListItemText>
								<Typography
										variant="body2"
										>
										•	You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.
									</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Choose someone to act for you
									</Typography>
								</ListSubheader>
								<ListItem disableGutters><ListItemText>
								<Typography
										variant="body2"
										>
										•	If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information
									</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	We will make sure the person has this authority and can act for you before we take any action.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									File a complaint if you feel your rights are violated.
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<ListItemText>
										<Typography
										variant="body2"
										>
										•	You can complain if you feel we have violated your rights by contacting us via email, mail or phone.<br/>
										RxFree4Me<br/>
										18230 Mack Ave<br/>
										Grosse Pointe Farms, MI 48236<br/>
										P: (844) 794-3733<br/>
										M: info@RxFree4Me.com.com
										</Typography>
										</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	You can file a complaint with the U.S. Department of Health and Human Services.  Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-6962.
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	We will not retaliate against you for filing a complaint.
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<Typography
								variant="h3"
								component="h3"
								color="secondary"             
								sx={{mt:4, mb: 2}}
							>
							Your Choices
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							<strong>For certain health information, you can tell us your choices about what we share.</strong> If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.
							</Typography>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									In these cases, you have both the right and choice to tell us to:
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	Share information with your family, close friends, or others involved in your care
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	Share information in a disaster relief situation
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	 Include your information in a hospital directory
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	 Contact you for fundraising efforts<br/>
										<em>If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.</em>
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	 Marketing purposes
										</Typography>
									</ListItemText>
								</ListItem>
								<ListItem disableGutters>
									<ListItemText>
									<Typography
										variant="body2"
										>
										•	 Sale of your information
										</Typography>
									</ListItemText>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									In these cases, we never share your information unless you give us written permission:
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	Most sharing of psychotherapy notes
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									In the case of fundraising:
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	We may contact you for fundraising efforts, but you can tell us not to contact you again.
									</Typography>
								</ListItem>
							</List>
							<Typography
								variant="h3"
								component="h3"
								color="secondary"             
								sx={{mt:4, mb: 2}}
							>
							Our Uses and Disclosures
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							<strong>How do we typically use or share your health information?</strong> We typically use or share your health information in the following ways.
							</Typography>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Treat you 
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	We can use your health with other professionals who are treating you.<br/>
										<em><strong>Example:</strong> We ask your physician information and share it for information when we review requests for medications which require authorization approval.</em>
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Run our organization
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•		We can use and share your health information to run our practice, improve your care, and contact you when necessary.<br/>
										<em><strong>Example:</strong> We use health information about you to manage your treatment and services.</em>
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
									Bill for your services
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										>
										•	We can use and share your health information to bill and get payment from health plans or other entities.<br/>
										<em><strong>Example:</strong> We give information about you to your health insurance plan so it will pay for your services</em>
									</Typography>
								</ListItem>
							</List>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							<strong>How else can we use or share your health information?</strong>We are allowed or required to share your information in other ways – usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes.<br/>For more information see: <a href="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html</a>.
							</Typography>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Help with public health and safety issues 
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										component="p"
										>
										We can share health information about you for certain situations such as:
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	Preventing disease
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	Helping with product recalls
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	Reporting adverse reactions to medications
									</Typography>
								</ListItem>

								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•		Reporting suspected abuse, neglect, or domestic violence
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	Preventing or reducing a serious threat to anyone’s health or safety
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Do research
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	We can use or share your information for health research.
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Comply with the law
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we’re complying with federal privacy law.
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Respond to organ and tissue donation requests
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	We can share health information about you with organ procurement organizations.
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Work with a medical examiner or funeral director
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	We can share health information with a coroner, medical examiner, or funeral director when the individual dies.
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Address workers’ compensation, law enforcement, and other government requests
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
								<Typography
										variant="body2"
										component="p"
										>
										We can use or share health information about you:
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	 For workers’ compensation claims
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	 For law enforcement purposes or with a law enforcement official
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	 With health oversight agencies for activities authorized by law
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	 For special government functions such as military, national security, and presidential protective services
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	 For workers’ compensation claims
									</Typography>
								</ListItem>
							</List>
							<List>
								<ListSubheader
									disableGutters
								>
									<Typography
										variant="h3"
										component="h4"
										color="black"
										sx={{mb: 1}}
									>
										Respond to lawsuits and legal actions
									</Typography>
								</ListSubheader>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  We can share health information about you in response to a court or administrative order, or in response to a subpoena.
									</Typography>
								</ListItem>
							</List>
							<Typography
								variant="h3"
								component="h3"
								color="secondary"             
								sx={{mt:4, mb: 2}}
							>
							Our Responsibilities
							</Typography>
							<List>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  We are required by law to maintain the privacy and security of your protected health information.
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  We must follow the duties and privacy practices described in this notice and give you a copy of it.
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  We will not use or share your information other than as described here unless you tell us we can in writing.
									</Typography>
								</ListItem>
								<ListItem disableGutters>
									<Typography
										variant="body2"
										>
										•	  If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.
									</Typography>
								</ListItem>
							</List>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							For more information see:<br/><a href="www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html." target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</a>.
							</Typography>
							<Typography
								variant="h3"
								component="h3"
								color="secondary"             
								sx={{mt:4, mb: 2}}
							>
							Changes to the Terms of This Notice
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
							>
							We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, in our office, and on our web site.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}
}
export default MemberRights;